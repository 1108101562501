<script>
    const host = `${process.env.VUE_APP_API_URL}`;
    export default {
        name: "ApiRouter",
        data: function () {
            return {
                routes: {
                    dailyReports: host + "/api/dailyReports",
                    dailyReport: function (id) {
                        return host + "/api/dailyReports/" + id;
                    },
                    displayMedicalCooperation: host + "/api/displayMedicalCooperation",
                    weather: host + "/api/weather",
                    childDailyReports: host + "/api/childDailyReports",
                    childDailyReport: function (id) {
                        return host + "/api/childDailyReports/" + id;
                    },
                    childDisplayMedicalCooperation: host + "/api/childDisplayMedicalCooperation",
                    InstructionReports: host + "/api/instructionReports",
                    InstructionReport: function (id) {
                        return host + "/api/instructionReports/" + id;
                    },
                    planReports: host + "/api/planReports",
                    planReport: function (id) {
                        return host + "/api/planReports/" + id;
                    },
                    doctorReports: host + "/api/doctorReports",
                    doctorReport: function (id) {
                        return host + "/api/doctorReports/" + id;
                    },
                    persons: host + "/api/persons",
                    person: function (id) {
                        return host + "/api/persons/" + id;
                    },
                    personRestore: function (id) {
                        return host + '/api/persons/restore/' + id;
                    },
                    selectPerson: host + "/api/selectPerson",
                    selectedPerson: host + "/api/selectedPerson",
                    facilityAll: host + "/api/facilityAll",
                    users: host + '/api/users',
                    user: function (id) {
                        return host + '/api/users/' + id;
                    },
                    userRestore: function (id) {
                        return host + '/api/users/restore/' + id;
                    },
                    offices: host + '/api/offices',
                    office: function (id) {
                        return host + '/api/offices/' + id;
                    },
                    officeRestore: function (id) {
                        return host + '/api/offices/restore/' + id;
                    },
                    officeAll: host + "/api/officeAll",
                    facilities: host + '/api/facilities',
                    facility: function (id) {
                        return host + '/api/facilities/' + id;
                    },
                    facilityRestore: function (id) {
                        return host + '/api/facilities/restore/' + id;
                    },
                }
            }
        }
    }
</script>
